const columnLabels = {
  "mapserver.g_pole": {
    "dbf_name":"DBF名",
    "cell_name":"CELL名",
    "cxf_attr_4":"電柱番号",
    "address":"住所",
    "cxf_attr_6":"名称",
    "cxf_attr_7":"種別",
    "cxf_attr_5":"種類",
    "cxf_attr_8":"電柱ID",
    "cxf_attr_1":"電柱AKEY",
    "has_attachments":"添付有無",
    "cell_id":"CELL ID",
    "dbf_id":"DBFのID",
    "para_id":"パラメータID",
    "adr_id":"住所ID",
    "attribute_type_id":"属性種別ID",
    "mrf_attr_1":"図形NO",
    "mrf_attr_2":"設備AKEY",
    "mrf_attr_6":"図形形状",
    "mrf_attr_7":"レイヤ",
    "mrf_attr_17":"最小X座標/経度",
    "mrf_attr_18":"最小Y座標/緯度",
    "mrf_attr_19":"最大X座標/経度",
    "mrf_attr_20":"最大Y座標/緯度",
    "mrf_attr_24":"修正日時",
    "mrf_attr_26":"線形状",
    "mrf_attr_31":"角度",
    "mrf_attr_37":"名称及び文字列",
    "registered_user":"登録ユーザID",
    "registered_user_name":"登録ユーザ名",
    "registered_on":"登録日",
    "updated_user":"更新ユーザID",
    "updated_user_name":"更新ユーザ名",
    "updated_on":"更新日",
  },
  "mapserver.g_messen": {
    "dbf_name":"DBF名",
    "cell_name":"CELL名",
    "address":"住所",
    "cxf_attr_1":"メッセンAKEY",
    "cxf_attr_5":"名称",
    "cxf_attr_6":"種別",
    "cxf_attr_4":"種類",
    "has_attachments":"添付有無",
    "cell_id":"CELL ID",
    "attribute_type_id":"属性種別ID",
    "dbf_id":"DBFのID",
    "para_id":"パラメータID",
    "adr_id":"住所ID",
    "mrf_attr_1":"図形NO",
    "mrf_attr_2":"設備AKEY",
    "mrf_attr_6":"図形形状",
    "mrf_attr_7":"レイヤ",
    "mrf_attr_17":"最小X座標/経度",
    "mrf_attr_18":"最小Y座標/緯度",
    "mrf_attr_19":"最大X座標/経度",
    "mrf_attr_20":"最大Y座標/緯度",
    "mrf_attr_24":"修正日時",
    "mrf_attr_26":"線形状",
    "mrf_attr_31":"角度",
    "mrf_attr_37":"名称及び文字列",
    "registered_user":"登録ユーザID",
    "registered_user_name":"登録ユーザ名",
    "registered_on":"登録日",
    "updated_user":"更新ユーザID",
    "updated_user_name":"更新ユーザ名",
    "updated_on":"更新日",
  },
  "mapserver.c_pole_appl_info": {
    "id":"ID",
    "pole_info":"申請電柱",
    "status":"ステータス",
    "occupancy":"占用",
    "pole_owner":"所有者",
    "address":"住所",
    "registered_login_id":"登録ユーザID",
    "registered_user":"登録ユーザ名",
    "registered_on":"登録日",
    "updated_login_id":"更新ユーザID",
    "updated_user":"更新ユーザ名",
    "updated_on":"更新日",
    "version":"バージョン",
  },
  "mapserver.v_pole_appl_poles_info": {
    "lng": "経度",
    "lat": "緯度",
    "occupancy": "占用",
    "address": "住所",
    "pole_num": "電柱番号",
    "pole_akey": "電柱Aキー",
    "pole_name": "電柱名",
    "version": "バージョン",
    "status": "ステータス",
    "pole_owner": "電柱オーナー",
    "pole_cell_id": "CELL ID",
  }
}

export default columnLabels