import Box from '@mui/material/Box';

export default function Footer() {
  return (
    <Box sx={{
      textAlign: 'right',
      backgroundColor: '#297642',
      color: 'lightgrey',
      padding: '8px',
    }}>
      v0.0.1
    </Box>
  );
}