import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import columnLabels from '../label/label';

const AttributeTable = ({feature, onClose}: {feature: any, onClose: any}) => {
  if (!feature) {
    return
  }
  
  const _keys = feature ? Object.keys(feature.properties) : [];
  let keys = _keys.filter(key => key !== 'the_geom' && key !== 'path' && key !== 'layerName')

  const layerName = feature.properties['layerName']
  //@ts-ignore
  const labels = columnLabels[layerName]
  if (labels) {
    const labelKeys = Object.keys(labels)
    keys = _keys.filter(key => labelKeys.includes(key))
  }

  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: '100%',
        minWidth: 400,
      }}
    >
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} style={{textAlign: "right"}}>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{minWidth: 200}}>属性名</TableCell>
            <TableCell style={{minWidth: 200}} align="right">値</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key:string) => (
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{minWidth: 200, textOverflow: 'ellipsis'}} component="th" scope="row">{labels[key]}</TableCell>
              <TableCell style={{minWidth: 200, textOverflow: 'ellipsis'}} align="right">{feature.properties[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AttributeTable;