import React, {useRef, useCallback, useState, createContext, useEffect} from 'react';
// import Mapbox from './mapbox';
import Slide from '@mui/material/Slide';
import DataGridDemo from './components/data-grid';
import PrimarySearchAppBar from './components/appbar';
import Footer from './components/footer';
import AttributeTable from './components/attribute-table';
import Map from './components/map';
import {MeiliSearch, SearchResponse} from "meilisearch";
import PanoramaDialog from './components/panorama/panorama-dialog';
import BuildingAttributeTable from './components/building-attribute-table';

const meili = new MeiliSearch({
  host: process.env.REACT_APP_MEILISEARCH_HOST!,
  apiKey: 'CADIX2user123456',
})

interface MapContextProps {
  selectedFeature: any,
  setSelectedFeature: Function,
  setPanoramaDialogOpen: Function,
  setBuildingAttrOpen: Function,
}
export const MapContext = createContext<Partial<MapContextProps>>({});

const App = () => {
  const [isDataGridOpen, setDataGridOpen] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResponse|null>(null);
  const [isAttrOpen, setAttrOpen] = useState(false);
  const [isBuildingAttrOpen, setBuildingAttrOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [isPanoramaDialogOpen, setPanoramaDialogOpen] = useState(false);

  const handleSearch = async (word:string) => {
    const result = await meili.index('c_pole_appl_info').search(word)
    console.log('reslt',result)
    setAttrOpen(false)
    setBuildingAttrOpen(false)
    setSearchResult(result)
  }

  const handleAttributeClose = () => {
    console.log('handleAttributeClose')
    setSelectedFeature(null)
    setAttrOpen(false)
  }
  const handleBuildingAttributeClose = () => {
    console.log('handleBuildingAttributeClose')
    setBuildingAttrOpen(false)
  }
  const handleDataGridClose = () => {
    console.log('handleDataGridClose')
    setDataGridOpen(false)
  }

  useEffect(() => {
    if (searchResult) {
      setDataGridOpen(true)
    } else {
      setDataGridOpen(false)
    }
  }, [searchResult])

  useEffect(() => {
    console.log('App:',selectedFeature)
    if (selectedFeature) {
      setDataGridOpen(false)
      setAttrOpen(true)
      setBuildingAttrOpen(false)
    } else {
      setAttrOpen(false)
    }
  }, [selectedFeature])

  useEffect(() => {
    if (isBuildingAttrOpen) {
      setDataGridOpen(false)
      setSelectedFeature(null)
    }
  }, [isBuildingAttrOpen])

  const handlePanoramaDialogClose = () => {
    console.log('handlePanoramaDialogClose')
    setPanoramaDialogOpen(false)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <MapContext.Provider value={{selectedFeature, setSelectedFeature, setPanoramaDialogOpen, setBuildingAttrOpen}}>
        <PrimarySearchAppBar onSearch={handleSearch} />
        <div style={{flex: 1, position: 'relative'}}>
          <Map />
          <Slide direction="left" in={isAttrOpen} mountOnEnter unmountOnExit>
            <div
              style={{
                position: 'absolute',
                // margin: '8px 8px 8px auto',
                top: '8px',
                right: '8px',
                width: '30%',
                height: 'calc(100% - 16px)'
              }}
            >
              <AttributeTable feature={selectedFeature} onClose={handleAttributeClose} />
            </div>
          </Slide>
          <Slide direction="left" in={isBuildingAttrOpen} mountOnEnter unmountOnExit>
            <div
              style={{
                position: 'absolute',
                // margin: '8px 8px 8px auto',
                top: '8px',
                right: '8px',
                width: '30%',
                height: 'calc(100% - 16px)'
              }}
            >
              <BuildingAttributeTable onClose={handleBuildingAttributeClose} />
            </div>
          </Slide>
          <Slide direction="up" in={isDataGridOpen} mountOnEnter unmountOnExit>
            <div
              style={{
                position: 'absolute',
                // margin: '8px 8px 8px auto',
                bottom: 0,
                
                width: '100%',
                // height: 'calc(100% - 16px)'
              }}
            >
              <DataGridDemo data={searchResult} onClose={handleDataGridClose}/>
            </div>
          </Slide>
          {isPanoramaDialogOpen && <PanoramaDialog
            open={isPanoramaDialogOpen}
            onClose={handlePanoramaDialogClose}
          />}
        </div>
        <Footer/>
      </MapContext.Provider>
    </div>
  );
}

export default App;