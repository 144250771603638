import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { SearchResponse } from 'meilisearch';
import { useContext } from 'react';
import { MapContext } from '../App';
import { Container } from '@mui/material';
import columnLabels from '../label/label';
import { PostgrestClient } from '@supabase/postgrest-js';
const postgrest = new PostgrestClient(process.env.REACT_APP_POSTGREST_HOST!)

// const columns = [
//   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//   },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 110,
//     editable: true,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params: any) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function DataGridDemo({data, onClose}: {data: SearchResponse|null, onClose: any}) {
  const {setSelectedFeature} = useContext(MapContext)

  if (!data || data.hits.length === 0) {
    return (
      <Paper sx={{ height: 500, margin: '8px'}}>
        <div style={{textAlign: 'right'}}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Container sx={{
          height: 'calc(100% - 40px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>検索結果はありません</Container>
      </Paper>
    )
  }

  let keys = Object.keys(data.hits[0])

  //@ts-ignore
  const labels = columnLabels[data.hits[0]['layerName']]
  if (labels) {
    const labelKeys = Object.keys(labels)
    keys = keys.filter(key => labelKeys.includes(key))
  }
  
  const columns = keys.map(key => ({
    field: key,
    headerName: labels[key],
    width: 100,
  }))
  return (
    <Paper sx={{ height: 500, margin: '8px'}}>
      <div style={{textAlign: 'right'}}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DataGrid
        sx={{backgroundColor: 'white'}}
        rows={data.hits}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        onRowSelectionModelChange={async (newRowSelectionModel) => {
          const selected = data.hits.find(d => d.id === newRowSelectionModel[0])
          console.log('onRowSelectionModelChange:',selected)
          const {data: result} = await postgrest.from('c_pole_appl_info').select().eq('id', selected!.id)

          console.log('result',result)
          setSelectedFeature?.({
            type: 'Feature',
            geometry: selected?.pole_geom_4326,
            properties: selected
          })
          // setRowSelectionModel(newRowSelectionModel);
        }}
        // pageSizeOptions={[5]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Paper>
  );
}