import {PathLayer, GeoJsonLayer, TextLayer} from '@deck.gl/layers/typed';
import {MVTLayer} from '@deck.gl/geo-layers/typed';
import {WebMercatorViewport} from '@deck.gl/core/typed';
import {Tile3DLayer, TerrainLayer} from '@deck.gl/geo-layers/typed';
import {_TerrainExtension} from '@deck.gl/extensions/typed';
import {ColumnLayer, PolygonLayer, IconLayer} from '@deck.gl/layers/typed';
import { PBFLoader } from './loader/pbf-loader';
import MyMVTLayer from '../components/layer/my-mvt-layer';
import {_GeoJSONLoader} from '@loaders.gl/json';
import type {Layer} from '@deck.gl/core/typed';
import {circle} from '@turf/turf';

export const createLayers = (
  selectedFeature?: any,
  setSelectedFeature?: Function,
  timestamp?: number,
  cameraClick?: Function,
): Array<Layer> => {
  // const viewport = new WebMercatorViewport(viewState);
  // const bbox = viewport.getBounds();

  const TILE_SERV_HOST = process.env.REACT_APP_TILE_SERV_HOST;
  
  console.log('***CREATELAYER')
  const layers: Array<Layer> = [
    // new Tile3DLayer({
    //   id: 'google-3d-tiles',
    //   data: 'https://tile.googleapis.com/v1/3dtiles/root.json',
    //   loadOptions: {
    //     fetch: {
    //       headers: {
    //         'X-GOOG-API-KEY': 'AIzaSyAWAuHR_UWK2fHFnhPPAc2zhRg0VgYaRAA'
    //       }
    //     }
    //   },
      // onTilesetLoad: tileset3d => {
      //   // tileset3d.options.onTraversalComplete = selectedTiles => {
      //   //   const credits = new Set();
      //   //   selectedTiles.forEach(tile => {
      //   //     const { copyright } = tile.content.gltf.asset;
      //   //     copyright.split(';').forEach(credits.add, credits);
      //   //     creditsElement.innerHTML = [...credits].join('; ');
      //   //   });
      //   //   return selectedTiles;
      //   // }
      // },
      // _subLayerProps: {
      //   scenegraph: {_lighting: 'flat'}
      // },
      // operation: 'terrain+draw'
    // }),
  ];

  layers.push(...[
    new MVTLayer({
      id: 'ms-messen',
      data: `${TILE_SERV_HOST}/mapserver.g_messen/{z}/{x}/{y}.mvt`,
      binary: false,
      minZoom: 12,
      maxZoom: 23,
      filled: true, // true
      stroked: false, // false
      extruded: true, // false
      wireframe: true, // false
      getFillColor: [0, 0, 0, 255],
      getLineWidth: 5,
      sizeUnits: 'meters',
      getLineColor: [60, 60, 60, 40],
      lineWidthMinPixels: 1,
      autoHighlight: true,
      highlightColor: [255, 0, 0, 100],
      uniqueIdProperty: 'id',

      pickable: true,
      onClick: (pickInfo, opt) => {
        setSelectedFeature?.(pickInfo.object)
      },
      renderSubLayers: props => {
        const {tile} = props;
        //@ts-ignore
        const data = tile.dataInWGS84

        return new PathLayer({
          id: props.id,
          data,
          jointRounded: true,
          getWidth: .5,
          sizeUnits: 'meters',
          lineWidthMinPixels: 1,
          pickable: true,
          autoHighlight: true,
          highlightColor: [255, 0, 0, 100],
          uniqueIdProperty: 'id',
          getPath: d => {
            // console.log('getPath::d',d)
            // console.log(d.geometry.coordinates)
            return d.geometry.coordinates.map((c: any) => [...c, 6.5])
          },
          getColor: (d:any) => {
            if (selectedFeature?.properties.id === `${d.properties.cell_id}_${d.properties.mrf_attr_1}`) {
              return [255, 0, 0];
            } else {
              const hex = d.properties.color;
              const color = hex.match(/[0-9a-f]{2}/ig).map((x: any) => parseInt(x, 16));
              return [...color, 100];
              }
          },
          // getWidth: d => .5,
          onClick: (feature, event) => {
            if (event.srcEvent.defaultPrevented) {
              return true;
            }
            // onFeatureClick(feature, event)
            return true;
          },
          updateTriggers: {
            getColor: [timestamp]
          },
        })
      },
    }),

    // new MVTLayer({
    //   id: 'c_pole_appl_info',
    //   data: `${TILE_SERV_HOST}/mapserver.c_pole_appl_info/{z}/{x}/{y}.mvt`,
    //   binary: false,
    //   minZoom: 12,
    //   maxZoom: 23,
    //   filled: true, // true
    //   stroked: false, // false
    //   extruded: true, // false
    //   wireframe: true, // false
    //   getFillColor: [0, 0, 0, 255],
    //   getLineWidth: 5,
    //   sizeUnits: 'meters',
    //   autoHighlight: true,
    //   highlightColor: [255, 0, 0, 100],
    //   getLineColor: [60, 60, 60, 40],
    //   lineWidthMinPixels: 1,
    //   // uniqueIdProperty: 'id',
    //   pickable: true,
    //   onClick: (pickInfo, opt) => {
    //     console.log(pickInfo, opt)
    //     setSelectedFeature?.(pickInfo.object)
    //   },
    //   renderSubLayers: props => {
    //     const {tile} = props;
    //     //@ts-ignore
    //     const data = tile.dataInWGS84
    //     //@ts-ignore
    //     const circles = tile.dataInWGS84.map((f: any) => {
    //       if (f.geometry.type === 'Point') {
    //         return circle(f.geometry.coordinates, 4, {steps: 16, units: 'meters', properties: f.properties})
    //       } else if (f.geometry.type === 'MultiPoint') {
    //         return f.geometry.coordinates.map((coord: any) => {
    //           return circle(coord, 4, {steps: 16, units: 'meters', properties: f.properties})
    //         })
    //       }
    //     })

    //     return new PolygonLayer({
    //       id: props.id,
    //       data: circles.flat(),
    //       stroked: true,
    //       filled: true,
    //       // wireframe: true,
    //       lineWidthMinPixels: 1,
    //       extruded: true, // false
    //       getElevation: (d: any) => 20,

    //       getPolygon: d => d.geometry.coordinates,
    //       getFillColor: d => {
    //         if (d.properties.status === 1) {
    //           return [255, 0, 0, 20]
    //         } else if (d.properties.status === 2) {
    //           return [0, 255, 0, 20]
    //         } else if (d.properties.status === 3) {
    //           return [0, 0, 255, 20]
    //         } else if (d.properties.status === 4) {
    //           return [255, 0, 255, 20]
    //         } else {
    //           return [80, 80, 80, 20]         
    //         }
    //       },
    //       getLineColor: d => {
    //         if (d.properties.status === 1) {
    //           return [255, 0, 0]
    //         } else if (d.properties.status === 2) {
    //           return [0, 255, 0]
    //         } else if (d.properties.status === 3) {
    //           return [0, 0, 255]
    //         } else if (d.properties.status === 4) {
    //           return [255, 0, 255]
    //         } else {
    //           return [80, 80, 80]              
    //         }
    //       },
    //       getLineWidth: 1
    //     });
    //   },
    // }),

    new MVTLayer({
      id: 'main-ms_pole',
      data: `${TILE_SERV_HOST}/mapserver.g_pole/{z}/{x}/{y}.mvt?filter=f_seq_no=1 AND name NOT LIKE '%25BUN'`,
      binary: false,
      minZoom: 12,
      maxZoom: 23,
      filled: true, // true
      stroked: false, // false
      extruded: true, // false
      wireframe: true, // false
      getFillColor: [0, 0, 0, 255],
      getLineWidth: 5,
      sizeUnits: 'meters',
      autoHighlight: true,
      highlightColor: [255, 0, 0, 100],
      getLineColor: [60, 60, 60, 40],
      lineWidthMinPixels: 1,
      getElevation: () => {
        // console.log('***getElevation');
        return 10 +90;
      },
      uniqueIdProperty: 'id',

      pickable: true,
      onClick: (pickInfo, opt) => {
        console.log(pickInfo, opt)
        setSelectedFeature?.(pickInfo.object)
      },
      renderSubLayers: props => {
        const {tile} = props;
        //@ts-ignore
        const data = tile.dataInWGS84

        return new ColumnLayer({
          id: props.id,
          data,
          diskResolution: 8,
          radius: 0.5,
          extruded: true,
          pickable: true,
          onClick: (feature, opt) => {
            console.log(feature, opt)
          },
          getPosition: d => d.geometry.coordinates,
          getFillColor: d => {
            if (selectedFeature?.properties.id === `${d.properties.cell_id}_${d.properties.mrf_attr_1}`) {
              return [255, 0, 0];
            } else {
              return [150, 150, 150, 255]
            }
          },
          getLineColor: [0, 0, 0],
          getElevation: d => 10,
          updateTriggers: {
            getFillColor: [timestamp]
          },
        })
      },
    }),

    new MVTLayer({
      id: 'v_pole_appl_poles_info',
      data: `${TILE_SERV_HOST}/mapserver.v_pole_appl_poles_info/{z}/{x}/{y}.mvt`,
      binary: false,
      minZoom: 12,
      maxZoom: 23,
      filled: true, // true
      stroked: false, // false
      extruded: true, // false
      wireframe: true, // false
      getFillColor: [0, 0, 0, 255],
      getLineWidth: 5,
      sizeUnits: 'meters',
      autoHighlight: true,
      highlightColor: [255, 0, 0, 100],
      getLineColor: [60, 60, 60, 40],
      lineWidthMinPixels: 1,
      // uniqueIdProperty: 'id',
      pickable: true,
      onClick: (pickInfo, opt) => {
        console.log(pickInfo, opt)
        setSelectedFeature?.(pickInfo.object)
      },
      renderSubLayers: props => {
        const {tile} = props;
        //@ts-ignore
        const data = tile.dataInWGS84
        //@ts-ignore
        const circles = tile.dataInWGS84.map((f: any) => {
          if (f.geometry.type === 'Point') {
            return circle(f.geometry.coordinates, 4, {steps: 16, units: 'meters', properties: f.properties})
          } else if (f.geometry.type === 'MultiPoint') {
            return f.geometry.coordinates.map((coord: any) => {
              return circle(coord, 4, {steps: 16, units: 'meters', properties: f.properties})
            })
          }
        })

        return new ColumnLayer({
          id: props.id,
          data,
          diskResolution: 16,
          radius: 2,
          extruded: true,
          pickable: true,
          onClick: (feature, opt) => {
            console.log(feature, opt)
          },
          getPosition: d => {
            if (d.geometry.type === 'Point') {
              return d.geometry.coordinates
            } else if (d.geometry.type === 'MultiPoint') {
              return d.geometry.coordinates[0]
            }
            return d.geometry.coordinates
          },
          getFillColor: d => {
            if (d.properties.status === 1) {
              return [255, 0, 0, 50]
            } else if (d.properties.status === 2) {
              return [0, 255, 0, 50]
            } else if (d.properties.status === 3) {
              return [0, 0, 255, 50]
            } else if (d.properties.status === 4) {
              return [255, 0, 255, 50]
            } else {
              return [80, 80, 80, 50]         
            }
          },
          getLineColor: [0, 0, 0],
          getElevation: d => 20,
          updateTriggers: {
            getFillColor: [timestamp]
          },
        })
      },
    }),

    new IconLayer({
      id: 'camera-icon',
      //@ts-ignore
      data: [[136.94288, 35.1662]],
      pickable: true,
      iconAtlas: '/images/camera.png',
      iconMapping: {
        marker: {x: 0, y: 0, width: 256, height: 256, mask: true, anchorY: 256}
      },
      getIcon: d => 'marker',
      sizeScale: 15,
      //@ts-ignore
      getPosition: (d:any) => {
        console.log('d',d)
        return [...d, 15]
      },
      getSize: d => 4,
      getColor: [255, 0, 100, 200],
      onClick: (pickInfo, opt) => {
        console.log(pickInfo)
        cameraClick?.()
      },
    }),

    // // Mapbox Building
    // new MVTLayer({
    //   id: 'mapbox',
    //   data: `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.vector.pbf?sku=101TZ65KRPKae&access_token=pk.eyJ1IjoiaWNoaXdha2kiLCJhIjoiY2sxaDVjeDJhMDNhZTNob2NmejVjaTVqZSJ9.1i4ALY-MWwrPAhOw9HPljg`,
    //   binary: true,
    //   minZoom: 12,
    //   maxZoom: 23,
    //   uniqueIdProperty: 'id',

    //   pickable: true,
    //   onClick: (pickInfo, opt) => {
    //     console.log(pickInfo, opt)
    //     setSelectedFeature?.(pickInfo.object)
    //   },
    //   renderSubLayers: props => {
    //     const {tile} = props;
    //     //@ts-ignore
    //     const data = tile.dataInWGS84.filter((f:any) => {
    //       return f.properties.layerName === 'building'
    //     })
    //     // console.log('data',data)

    //     return new PolygonLayer({
    //       id: props.id,
    //       data,
    //       stroked: true,
    //       filled: true,
    //       wireframe: true,
    //       lineWidthMinPixels: 1,
    //       extruded: true, // false
    //       getElevation: (d: any) => d.properties.height,
    //       getPolygon: d => d.geometry.coordinates,
    //       getFillColor: [255, 255, 255, 60],
    //       getLineColor: [0, 0, 0],
    //       getLineWidth: 2
    //     })
    //   },
    // }),

    // new MVTLayer({
    //   id: 'c_pole_appl_info-highlight',
    //   data: `${TILE_SERV_HOST}/mapserver.c_pole_appl_info/{z}/{x}/{y}.mvt`,
    //   binary: false,
    //   minZoom: 12,
    //   maxZoom: 23,
    //   filled: true, // true
    //   stroked: false, // false
    //   extruded: true, // false
    //   wireframe: true, // false
    //   getFillColor: [0, 0, 0, 255],
    //   getLineWidth: 5,
    //   sizeUnits: 'meters',
    //   autoHighlight: true,
    //   highlightColor: [255, 0, 0, 100],
    //   getLineColor: [60, 60, 60, 40],
    //   lineWidthMinPixels: 1,
    //   getElevation: () => {
    //     // console.log('***getElevation');
    //     return 10 +90;
    //   },
    //   uniqueIdProperty: 'id',
    //   pickable: false,
    //   renderSubLayers: props => {
    //     const {tile} = props;
    //     //@ts-ignore
    //     const data = tile.dataInWGS84

    //     return new IconLayer({
    //       id: props.id,
    //       data,
    //       // pickable: true,
    //       // iconAtlas and iconMapping are required
    //       // getIcon: return a string
    //       iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    //       iconMapping: {
    //         marker: {x: 0, y: 0, width: 128, height: 128, mask: true, anchorY: 180}
    //       },
    //       getIcon: d => 'marker',
    //       sizeScale: 15,
    //       getPosition: d => {
    //         if (d.geometry.type === 'Point') {
    //           return [...(d.geometry.coordinates), 10]
    //         } else if (d.geometry.type === 'MultiPoint') {
    //           return [...(d.geometry.coordinates[0]), 10]
    //         }
    //         return d.geometry.coordinates
    //         // console.log('getPosition:::2', d.geometry.coordinates.map((c: any) => [...c, 10]))
    //       },
    //       getSize: d => 4,
    //       getColor: d => {
    //         if (d.properties.status === 1) {
    //           return [255, 0, 0, 255]
    //         } else if (d.properties.status === 2) {
    //           return [0, 255, 0, 255]
    //         } else if (d.properties.status === 3) {
    //           return [0, 0, 255, 255]
    //         } else if (d.properties.status === 4) {
    //           return [255, 0, 255, 255]
    //         } else {
    //           return [80, 80, 80, 0]         
    //         }
    //       }
    //     });
    //   },
    // }),
  ]);

  if (selectedFeature) {
    let geom = selectedFeature.geometry
    if (selectedFeature.geometry.type === 'MultiPoint') {
      geom = selectedFeature.geometry.coordinates
    }
    const highlight = new IconLayer({
      id: 'highlight-icon',
      data: geom,
      // pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      iconMapping: {
        marker: {x: 0, y: 0, width: 128, height: 128, mask: true, anchorY: 180}
      },
      getIcon: d => 'marker',
      sizeScale: 15,
      //@ts-ignore
      getPosition: (d:any) => {
        console.log('d',d)
        return [...d, 20]
      },
      getSize: d => 4,
      getColor: [255, 0, 0, 200],
    });

    layers.push(highlight)
  }

  return layers;
}
