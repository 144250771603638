import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import columnLabels from '../label/label';
import { Button, Slide } from '@mui/material';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BuildingAttributeTable = ({onClose}: {onClose: any}) => {
  const [floor, setFloor] = useState<number>()

  const handleFloorClick = (floor:number) => {
    setFloor(floor)
  }

  const handleBackClick = () => {
    setFloor(undefined)
  }

  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: '100%',
        minWidth: 200,
      }}
    >
      {!floor &&
        <Slide direction="right" in={!floor} mountOnEnter unmountOnExit>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={{textAlign: "right"}}>
                  <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Button variant="outlined" onClick={() => handleFloorClick(3)}>3F</Button>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Button variant="outlined" onClick={() => handleFloorClick(2)}>2F</Button>
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Button variant="outlined" onClick={() => handleFloorClick(1)}>1F</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Slide>
      }
      {floor &&
        <Slide direction="left" in={!!floor} mountOnEnter unmountOnExit>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={{textAlign: "left"}}>
                  <IconButton aria-label="back" onClick={handleBackClick}>
                    <ArrowBackIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell scope="row">
                  <img src="/images/floor.jpg" style={{width: '100%', objectFit: 'fill'}} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Slide>
      }
    </TableContainer>
  );
}

export default BuildingAttributeTable;