import React, {useCallback,useEffect, useState, forwardRef} from 'react';
import { styled } from "@mui/system";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Slide from '@mui/material/Slide';
import { Hotspot, Krpano, Scene } from '@0xllllh/react-krpano';

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PanoramaDialog(props) {
  const { onClose, open } = props;
  const [currentScene, setCurrentScene] = useState('scene0');

  const handleClose = () => {
    onClose();
  }

  const scenes = [{
    name: 'scene0',
    images: [{
      type: 'sphere',
      url: '/krpano/IMG_0095.JPG',
    }],
    hotspots: [{
      name: "hotspot0",
      ath: 188,
      atv: -35,
      style: "hotspot_ani_white",
      onclick: () => setCurrentScene('scene1')
    }]
  }, {
    name: 'scene1',
    images: [{
      type: 'sphere',
      url: '/krpano/R0010026.JPG',
    }],
    hotspots: [{
      name: "hotspot1",
      ath: -150,
      atv: 25,
      style: "hotspot_ani_white",
      onclick: () => setCurrentScene('scene0')
    }]
  }];

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Krpano currentScene={currentScene} xml="krpano/test.xml">
        {scenes.map(sc => (
          <Scene {...sc}>
            {sc.hotspots.map(hs => <Hotspot {...hs} />)}
          </Scene>
        ))}
      </Krpano>
    </Dialog>
  );
}
