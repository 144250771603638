import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from '@emotion/styled';
import { buildingLayer } from '../config/mapbox-3d-buildings';
import { Sky } from '../config/mapbox-sky';
import type {Layer} from '@deck.gl/core/typed';
import {MapboxOverlay} from '@deck.gl/mapbox/typed';
import {ScatterplotLayer} from '@deck.gl/layers/typed';
import { createLayers } from '../util/create-layers';
import { MapContext } from '../App';
import * as turf from '@turf/turf';
import { PostgrestClient } from '@supabase/postgrest-js';
const postgrest = new PostgrestClient(process.env.REACT_APP_POSTGREST_HOST!)

const MapContainer = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}));

const defaultPosition: mapboxgl.EaseToOptions = {
  center: [136.94241769935525, 35.16614678884218],
  zoom: 18,
  pitch: 45,
}

const Map: React.FC = () => {
  // mapboxgl.Mapのインスタンスへの参照を保存するためのuseState
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
  const [deckOverlay, setDeckOverlay] = useState<MapboxOverlay>();

  // 地図表示するDiv要素を特定するためのuseRef
  const mapContainer = useRef<HTMLDivElement | null>(null);
  
  const {selectedFeature, setSelectedFeature, setPanoramaDialogOpen, setBuildingAttrOpen} = useContext(MapContext)

  const keydownEventHandler = (event: KeyboardEvent) => {
    console.log('keydownEventHandler',selectedFeature)
    if (event.key === "Escape") {
      setSelectedFeature?.(null)
      setBuildingAttrOpen?.(false)
    }
  };

  const handleCameraClick = () => {
    console.log('handleCameraClick')
    setPanoramaDialogOpen?.(true)
  }

  useEffect(() => {
    // mapContainer.currentはnullになり得るので型ガード（ていねい）
    if (!mapContainer.current) return;

    const map = new mapboxgl.Map({
      container: mapContainer.current, // ていねいな型ガードのおかげで必ずHTMLDivElementとして扱える、current!でも可
      style: 'mapbox://styles/ichiwaki/clc49x37b000114s8kf6dyw3t',
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      antialias: true,
    });

    map.on('click', async (e) => {
      const buildings = map.queryRenderedFeatures(e.point, {
        layers: ['add-3d-buildings']
      });
  
      if (buildings && buildings.length > 0) {
        console.log('buildings',buildings)
        setBuildingAttrOpen?.(true)
      } else {
        setBuildingAttrOpen?.(false)
      }
    })

    map.on('style.load', async () => {
      const {data} = await postgrest.from('system_settings').select().eq('key', '3d_first_position')
      const pos: mapboxgl.EaseToOptions = data && data[0] ? JSON.parse(data[0]['value']) : defaultPosition
      map.easeTo(pos)

      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;
      //@ts-ignore
      const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;
      const filtered = layers.filter((layer) => layer.type === 'symbol' && layer.layout?.['text-field']);
      console.log('filtered',filtered)
       
      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      //@ts-ignore
      map.addLayer(buildingLayer, labelLayerId)
      //@ts-ignore
      map.addLayer(Sky);

      // map.addSource('mapbox-dem', {
      //   'type': 'raster-dem',
      //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      //   'tileSize': 512,
      //   'maxzoom': 14
      // });
      // // add the DEM source as a terrain layer with exaggerated height
      // map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      // Add a new vector tile source with ID 'mapillary'.
      map.addSource('g_pole', {
        'type': 'vector',
        'tiles': [
          `${process.env.REACT_APP_TILE_SERV_HOST}/mapserver.g_pole/{z}/{x}/{y}.mvt?filter=f_seq_no=1`
        ],
        'minzoom': 18,
        'maxzoom': 20
      });
      map.addLayer({
        'id': 'g_pole_text', // Layer ID
        'type': 'symbol',
        'source': 'g_pole', // ID of the tile source created above
        'source-layer': 'mapserver.g_pole',
        'layout': {
          'text-field': ['get', 'cxf_attr_4'],
          'text-font': [
            'Open Sans Bold',
            'Arial Unicode MS Bold'
          ],
          'text-size': 11,
          // 'text-transform': 'uppercase',
          'text-letter-spacing': 0.05,
          'text-offset': [0, 1.5]
        },
        'paint': {
          'text-color': '#222',
          'text-halo-color': '#fff',
          'text-halo-width': 2
        },
      });

      // map.addSource('c_pole_appl_info', {
      //   'type': 'vector',
      //   'tiles': [
      //     'https://tile.altlas.co.jp/mapserver.c_pole_appl_info/{z}/{x}/{y}.mvt'
      //   ],
      //   'minzoom': 18,
      //   'maxzoom': 22
      // });
      // map.addLayer({
      //   'id': 'c_pole_appl_info', // Layer ID
      //   'type': 'circle',
      //   'source': 'c_pole_appl_info', // ID of the tile source created above
      //   'source-layer': 'mapserver.c_pole_appl_info',
      //   'layout': {
      //   },
      //   'paint': {
      //     'circle-color': '#222',
      //     'circle-radius': 10,
      //     'circle-stroke-color': '#f00',
      //     'circle-stroke-width': 2
      //   },
      // });

      const overlayLayers = createLayers(selectedFeature, setSelectedFeature, undefined, handleCameraClick)
      const overlay = new MapboxOverlay({
        interleaved: true,
        layers: overlayLayers,
      });
    
      map.addControl(overlay);

      setDeckOverlay(overlay);
    });

    // mapboxgl.Mapのインスタンスへの参照を保存
    setMapInstance(map);

    document.addEventListener("keydown", keydownEventHandler, false);

    return () => {
      document.removeEventListener("keydown", keydownEventHandler, false);
    }
  }, []);
  
  useEffect(() => {
    console.log('map:selectedFeature:effect',selectedFeature)
    if (selectedFeature) {
      if (selectedFeature.geometry.type == 'MultiPoint') {
        let bb = turf.bbox(selectedFeature) as [number, number, number, number]
        const polygon = turf.bboxPolygon(bb)
        const buf = turf.buffer(polygon, 0.02)
        bb = turf.bbox(buf) as [number, number, number, number]
        mapInstance?.fitBounds(bb)
      } else {
        console.log(selectedFeature.geometry.coordinates[0])
        mapInstance?.flyTo({
          center: [selectedFeature.properties.lng, selectedFeature.properties.lat],
          zoom: 20,
        })
      }
    }
    // mapInstance?.triggerRepaint()
    const layers = createLayers(selectedFeature, setSelectedFeature, Date.now(), handleCameraClick)
    deckOverlay?.setProps({
      layers: layers?.map(l => {
        return l.clone({
          updateTriggers: {
            getColor: [Date.now()],
          },
        });
      }),
    })

  }, [selectedFeature])

  return (
    <MapContainer ref={mapContainer} />
  )
};

export default Map;